<template>
  <el-row justify="center" align="middle">
    <el-col :sm="{span: 16, offset: 4}">
      <survey-splash
        :questions-fetched="!fetchingQuestions"
        :survey-splash-subheader="surveySplashSubheader"
        :survey-splash-items="surveySplashItems"
        @submit="handleSplashSubmit" />
    </el-col>
  </el-row>
</template>

<script>
import SurveySplash from '@/components/signup/survey/SurveySplash';

export default {
  name: 'SplashView',
  components: {
    SurveySplash
  },
  computed: {
    surveySplashSubheader() {
      return this.$store.getters.surveySplashSubheader;
    },
    surveySplashItems() {
      return this.$store.getters.surveySplashItems;
    },
    fetchingQuestions() {
      return this.$store.state.survey.fetchingQuestions;
    }
  },
  methods: {
    handleSplashSubmit() {
      this.$router.push({ path: '/signup/survey/start' });
    }
  }
};
</script>
