<template>
  <div class="survey-splash-component">
    <h1 class="survey-header">
      Now, Let's setup your account
    </h1>
    <p class="survey-sub-header">
      {{ surveySplashSubheader }}
    </p>
    <form @submit.prevent="$emit('submit')">
      <survey-splash-item
        v-for="surveySplashItem in surveySplashItems"
        :key="surveySplashItem.name"
        :splash-item="surveySplashItem" />
      <el-button
        :loading="!questionsFetched"
        type="primary"
        class="next-button"
        native-type="submit">
        Setup My Account <i class="el-icon-arrow-right" />
      </el-button>
      <p class="time-copy">
        ⏱&nbsp;&nbsp;This will take you about five (5) minutes
      </p>
    </form>
  </div>
</template>

<script>
import SurveySplashItem from '@/components/signup/survey/SurveySplashItem';

export default {
  name: 'SurveySplash',
  components: {
    SurveySplashItem
  },
  props: {
    surveySplashSubheader: {
      type: String,
      default: ''
    },
    surveySplashItems: {
      type: Array,
      required: true
    },
    questionsFetched: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-header {
  margin-bottom: 0;
}

.survey-sub-header {
  font-size: $--jb-font-size-small;
  margin-bottom: 32px;
  margin-top: 8px;
}

.time-copy {
  font-size: 13px;
  margin-top: 0;
  text-align: center;
  position: absolute;
  margin-bottom: 32px;
  width: 100%;
}
</style>
